import {
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useRequest } from "../../services/request";
import EditIcon from "@mui/icons-material/Edit";

export default function TagMetaData() {
  const [loading, setLoading] = useState(false);
  const [tagMetaData, setTagMetaData] = useState(null);
  const [tagMetaDataForm, setTagMetaDataForm] = useState({
    android_app_url: "",
    ios_app_url: "",
    custom_page_url: "",
    code: "",
  });

  const req = useRequest();

  useEffect(() => {
    (async () => {
      const resData = await req(`manufacturers/tag_meta_data/`, null, {}, true);
      setTagMetaData(resData);
      setTagMetaDataForm(resData);
    })();
  }, [req]);

  const handleClick = () => {
    (async () => {
      try {
        setLoading(true);
        const resData = await req(
          `manufacturers/tag_meta_data/`,
          tagMetaDataForm,
          { method: "PATCH" },
          true
        );
        setTagMetaData({ ...resData });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  };

  if (!tagMetaData) return null;

  return (
    <div>
      <Typography variant="h4" sx={{ my: 4 }}>
        Tag Meta Data
      </Typography>

      <Box sx={{ maxWidth: 700 }}>
        <Card variant="outlined" sx={{ mb: 3 }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} gutterBottom>
              <strong>Custom Page Url:</strong> {tagMetaData["custom_page_url"]}
            </Typography>
            <Typography sx={{ fontSize: 14 }} gutterBottom>
              <strong>Android App Url:</strong>{" "}
              {tagMetaData["android_app_url"]}
            </Typography>

            <Typography sx={{ fontSize: 14 }} gutterBottom>
              <strong>IOS App Url:</strong> {tagMetaData["ios_app_url"]}
            </Typography>

            <Typography sx={{ fontSize: 14 }} gutterBottom>
              <strong>Code:</strong> {tagMetaData["code"]}
            </Typography>
          </CardContent>
        </Card>

        <Card variant="outlined" sx={{ mb: 3 }}>
          <CardContent>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Android App URL"
              name="android_app_url"
              onChange={(e) =>
                setTagMetaDataForm((v) => ({
                  ...v,
                  android_app_url: e.target.value,
                }))
              }
              autoFocus
              value={tagMetaDataForm.android_app_url}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              label="IOS App URL"
              name="ios_app_url"
              value={tagMetaDataForm.ios_app_url}
              onChange={(e) =>
                setTagMetaDataForm((v) => ({
                  ...v,
                  ios_app_url: e.target.value,
                }))
              }
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              label="Custom Page URL"
              name="custom_page_url"
              value={tagMetaDataForm.custom_page_url}
              onChange={(e) =>
                setTagMetaDataForm((v) => ({
                  ...v,
                  custom_page_url: e.target.value,
                }))
              }
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              label="Code"
              name="code"
              value={tagMetaDataForm.code}
              onChange={(e) =>
                setTagMetaDataForm((v) => ({ ...v, code: e.target.value }))
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </CardContent>

          <CardActions
            sx={{ borderTop: "#ccc 1px solid", borderColor: "grey.300", p: 2 }}
          >
            <Button
              variant="outlined"
              size="small"
              startIcon={<EditIcon />}
              onClick={handleClick}
              disabled={loading}
            >
              Save
            </Button>
          </CardActions>
        </Card>
      </Box>
    </div>
  );
}
