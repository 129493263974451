import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useRequest } from "../../services/request";
import EditIcon from "@mui/icons-material/Edit";
import Tiptap from "./Tiptap";
import Snackbar from "@mui/material/Snackbar";

export default function Certificate() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);

  const [certificate, setCertificate] = useState({
    name: "",
    identity_address: "",
    verified_item_certificate: "",
  });

  const req = useRequest();

  useEffect(() => {
    (async () => {
      const id = JSON.parse(window.localStorage.getItem("user")).user
        .manufacturer.id;
      const resData = await req(`manufacturers/${id}/`, null, {}, true);
      setCertificate(resData);
      setFetching(false);
    })();
  }, [req]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = () => {
    (async () => {
      const id = JSON.parse(window.localStorage.getItem("user")).user
        .manufacturer.id;
      try {
        setLoading(true);
        const resData = await req(
          `manufacturers/${id}/`,
          certificate,
          { method: "PATCH" },
          true
        );
        setCertificate({ ...resData });
        setOpen(true);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  };

  if (fetching) return null;

  return (
    <div>
      <Typography variant="h4" sx={{ my: 4 }}>
        Certificate
      </Typography>

      <Box sx={{ maxWidth: 700 }}>
        <Card variant="outlined" sx={{ mb: 3 }}>
          <CardContent>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Name"
              name="name"
              onChange={(e) =>
                setCertificate((v) => ({
                  ...v,
                  name: e.target.value,
                }))
              }
              autoFocus
              value={certificate.name}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              label="Identity Address"
              name="identity_address"
              value={certificate.identity_address}
              onChange={(e) =>
                setCertificate((v) => ({
                  ...v,
                  identity_address: e.target.value,
                }))
              }
              InputLabelProps={{
                shrink: true,
              }}
            />

            <InputLabel sx={{ mt: 2, mb: 1 }}>
              Verified Item Certificate
            </InputLabel>

            <Tiptap
              content={certificate.verified_item_certificate + ""}
              onUpdate={(html) =>
                setCertificate((v) => ({
                  ...v,
                  verified_item_certificate: html,
                }))
              }
            />
          </CardContent>

          <CardActions
            sx={{ borderTop: "#ccc 1px solid", borderColor: "grey.300", p: 2 }}
          >
            <Button
              variant="outlined"
              size="small"
              startIcon={<EditIcon />}
              onClick={handleSubmit}
              disabled={loading}
            >
              Save
            </Button>

            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="success"
                variant="filled"
                sx={{ width: "100%" }}
              >
                Saved successfully.
              </Alert>
            </Snackbar>
          </CardActions>
        </Card>
      </Box>
    </div>
  );
}
