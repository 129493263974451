import { memo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import ExtensionIcon from "@mui/icons-material/Extension";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ApprovalIcon from '@mui/icons-material/Approval';

import Header from "./Header";
import { useAuth } from "../services/auth";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";

export const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function Layout({ children }) {
  const auth = useAuth();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const [openSub, setOpenSub] = useState(
    ["/sdk/android", "/sdk/ios"].includes(location.pathname)
  );

  useEffect(() => {
    setOpen(window.innerWidth < 1200 || !auth.user ? false : true);
  }, [auth.user]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuClick = () => {
    setOpenSub(!openSub);
  };

  const listItemStyles = {
    minHeight: 48,
    justifyContent: open ? "initial" : "center",
    px: 2.5,
  };

  const listItemIconStyles = {
    minWidth: 0,
    mr: open ? 3 : "auto",
    justifyContent: "center",
  };

  const listItemTextStyles = { opacity: open ? 1 : 0 };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Header handleDrawerOpen={handleDrawerOpen} open={open} />

      {auth.user && (
        <Drawer variant="permanent" open={open} data-testid="drawer">
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>

          <Divider />

          <List role="menu">
            <ListItemButton
              role="menuitem"
              component={Link}
              selected={location.pathname === "/"}
              to="/"
              sx={listItemStyles}
            >
              <ListItemIcon sx={listItemIconStyles}>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" sx={listItemTextStyles} />
            </ListItemButton>

            <ListItemButton
              role="menuitem"
              component={Link}
              selected={location.pathname === "/manufacturer-apps"}
              to="/manufacturer-apps"
              sx={listItemStyles}
            >
              <ListItemIcon sx={listItemIconStyles}>
                <PhoneIphoneIcon />
              </ListItemIcon>
              <ListItemText primary="My Apps" sx={listItemTextStyles} />
            </ListItemButton>

            <ListItemButton
              role="menuitem"
              selected={["/sdk/android", "/sdk/ios"].includes(
                location.pathname
              )}
              sx={listItemStyles}
              onClick={handleMenuClick}
            >
              <ListItemIcon sx={listItemIconStyles}>
                <ExtensionIcon />
              </ListItemIcon>
              <ListItemText primary="SDK" sx={listItemTextStyles} />
              {openSub ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openSub} timeout="auto" unmountOnExit>
              <List component="div" disablePadding dense sx={{backgroundColor: 'grey.50' }}>
                <ListItemButton
                  sx={{ pl: 4, borderTop: "#ccc 1px solid", borderColor: 'grey.300' }}
                  component={Link}
                  to="/sdk/android"
                  selected={location.pathname === "/sdk/android"}
                >
                  <ListItemIcon>
                    <AndroidIcon />
                  </ListItemIcon>
                  <ListItemText primary="Android" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 4 }}
                  component={Link}
                  to="/sdk/ios"
                  selected={location.pathname === "/sdk/ios"}
                >
                  <ListItemIcon>
                    <AppleIcon />
                  </ListItemIcon>
                  <ListItemText primary="IOS" />
                </ListItemButton>
              </List>
            </Collapse>

            <ListItemButton
              role="menuitem"
              component={Link}
              selected={location.pathname === "/tag-meta-data"}
              to="/tag-meta-data"
              sx={listItemStyles}
            >
              <ListItemIcon sx={listItemIconStyles}>
                <LocalOfferIcon />
              </ListItemIcon>
              <ListItemText primary="Tag Meta Data" sx={listItemTextStyles} />
            </ListItemButton>

            <ListItemButton
              role="menuitem"
              component={Link}
              selected={location.pathname === "/certificate"}
              to="/certificate"
              sx={listItemStyles}
            >
              <ListItemIcon sx={listItemIconStyles}>
                <ApprovalIcon />
              </ListItemIcon>
              <ListItemText primary="Certificate" sx={listItemTextStyles} />
            </ListItemButton>
          </List>
        </Drawer>
      )}

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {auth.user && <DrawerHeader />}
        {children}
      </Box>
    </Box>
  );
}

export default memo(Layout);
