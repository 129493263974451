// src/Tiptap.tsx
import { Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  useEditor,
  EditorContent,
  // FloatingMenu,
  // BubbleMenu,
} from "@tiptap/react";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import TextAlign from "@tiptap/extension-text-align";
import Heading from "@tiptap/extension-heading";
import Paragraph from "@tiptap/extension-paragraph";
import Document from "@tiptap/extension-document";
import Text from "@tiptap/extension-text";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import BulletList from "@tiptap/extension-bullet-list";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import HorizontalRule from "@tiptap/extension-horizontal-rule";

// define your extension array
const extensions = [
  Text,
  Document,
  Heading,
  Paragraph,
  Bold,
  Italic,
  BulletList,
  ListItem,
  OrderedList,
  HorizontalRule,
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
];

const EditorItem = ({ label, handleClick, cond }) => {
  return (
    <Typography
      as="div"
      sx={{
        fontWeight: "500",
        backgroundColor: cond ? "#d3e2ff" : "transparent",
        color: cond ? "#111" : "#888",
        height: 26,
        minWidth: 26,
        borderRadius: "4px",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ":hover": {
          backgroundColor: cond ? "#d3e2ff" : "#eee",
        },
      }}
      onClick={handleClick}
      // disabled={!editor.can().chain().focus().toggleBold().run()}
      className={cond ? "is-active" : ""}
    >
      {label}
    </Typography>
  );
};

const Tiptap = ({ onUpdate, content }) => {
  const editor = useEditor({
    extensions,
    content: content,
    onUpdate: () => {
      onUpdate(editor.getHTML());
    },
  });

  return (
    <>
      <Paper sx={{ p: 2, height: 200 }} variant="outlined">
        <Box
          sx={{
            gap: "3px",
            display: "flex",
            alignItems: "center",
            marginBottom: "3px",
          }}
        >
          <EditorItem
            label={<FormatBoldIcon fontSize="small" />}
            cond={editor.isActive("bold")}
            handleClick={() => editor.chain().focus().toggleBold().run()}
          />

          <EditorItem
            label={<FormatItalicIcon fontSize="small" />}
            cond={editor.isActive("italic")}
            handleClick={() => editor.chain().focus().toggleItalic().run()}
          />

          <EditorItem
            label={<Box sx={{ px: "5px" }}>H1</Box>}
            cond={editor.isActive("heading", { level: 1 })}
            handleClick={() =>
              editor.chain().focus().toggleHeading({ level: 1 }).run()
            }
          />
          <EditorItem
            label={<Box sx={{ px: "5px", fontSize: 15 }}>H2</Box>}
            cond={editor.isActive("heading", { level: 2 })}
            handleClick={() =>
              editor.chain().focus().toggleHeading({ level: 2 }).run()
            }
          />
          <EditorItem
            label={<Box sx={{ px: "5px", fontSize: 14 }}>H3</Box>}
            cond={editor.isActive("heading", { level: 3 })}
            handleClick={() =>
              editor.chain().focus().toggleHeading({ level: 3 }).run()
            }
          />
          <EditorItem
            label={<Box sx={{ px: "5px", fontSize: 13 }}>H4</Box>}
            cond={editor.isActive("heading", { level: 4 })}
            handleClick={() =>
              editor.chain().focus().toggleHeading({ level: 4 }).run()
            }
          />
          <Divider orientation="vertical" variant="middle" flexItem />
          <EditorItem
            label={<FormatAlignLeftIcon fontSize="small" />}
            cond={editor.isActive({ textAlign: "left" })}
            handleClick={() =>
              editor.chain().focus().setTextAlign("left").run()
            }
          />
          <EditorItem
            label={<FormatAlignCenterIcon fontSize="small" />}
            cond={editor.isActive({ textAlign: "center" })}
            handleClick={() =>
              editor.chain().focus().setTextAlign("center").run()
            }
          />
          <EditorItem
            label={<FormatAlignRightIcon fontSize="small" />}
            cond={editor.isActive({ textAlign: "right" })}
            handleClick={() =>
              editor.chain().focus().setTextAlign("right").run()
            }
          />
          <EditorItem
            label={<FormatListBulletedIcon fontSize="small" />}
            cond={editor.isActive("bulletList")}
            handleClick={() => editor.chain().focus().toggleBulletList().run()}
          />
          <EditorItem
            label={<FormatListNumberedIcon fontSize="small" />}
            cond={editor.isActive("orderedList")}
            handleClick={() => editor.chain().focus().toggleOrderedList().run()}
          />
          <Divider orientation="vertical" variant="middle" flexItem />
          <EditorItem
            label={<HorizontalRuleIcon fontSize="small" />}
            cond={false}
            handleClick={() => editor.chain().focus().setHorizontalRule().run()}
          />
        </Box>

        <Divider />

        <EditorContent editor={editor} />
      </Paper>
      {/* <FloatingMenu editor={editor}>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
        >
          bullet list
        </button>
      </FloatingMenu>
      <BubbleMenu editor={editor}>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
        >
          bullet list
        </button>
      </BubbleMenu> */}
    </>
  );
};

export default Tiptap;
